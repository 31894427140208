import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { App, Avatar, Button, Flex, Spin } from 'antd'
import { format, parseISO } from 'date-fns'
import styled from 'styled-components'
import { IssueSvgCard, IssuesCard } from 'tabs/issuesCard'
import { DayWrapper } from 'tabs/notes/NotesTab.styled'
import { calculateAge } from 'utils/Dob'
import {
  PatientInfo,
  Span,
  ButtonContainer,
  CardWrapper,
  Text,
  DayText,
  Section,
  TextTitle,
  FeedbackContent,
  EmptyText,
  DateWraapper,
  CalenderWrapper,
  MetricWrapper,
  MetricCard,
  MetricTitle,
  MetricValue,
  PatientInfoItem,
  TreatmentTypeContainer,
  TreatmentType,
  NameWrapper,
  Hexagon,
  PopUp,
  HexagonContainer,
  HexagonLabel,
  ScanReportContainer,
  ScanReportWrapper,
  LeftBar,
  RightBar,
  LeftBarContainer,
  RightBarContainer,
  LeftCircle,
  RightCircle,
  Footer,
  ProfileContainer,
  ScanDayAndDate,
  MonthAndDate,
  Day,
  ScoreContainer,
  Score,
  PageToggle,
  Arrows,
  Container,
  LoadingContainer,
  ScanDueIn,
  ButtonChildren,
  AgeBadge,
  FlashingMetricCard,
  LeftBarText,
  RightBarText,
} from './CurrentTab.style'
import { useLobbyPolling } from '../../hooks/useLobbyPolling'
import { useRecordingHook } from '../../hooks/useRecordingHook'
import useScanPolling from '../../hooks/useScanPolling'
import errorSvg from '../../images/error.svg'
import KiroLogo from '../../images/kiro-logo-full.svg'
import KiroLogoRed from '../../images/kiro-Logo-Red.svg'
import leftArrow from '../../images/leftArrow.svg'
import NextPatient from '../../images/nextPatient.svg'
import rightArrow from '../../images/rightArrow.svg'
import Start from '../../images/start.svg'
import Stop from '../../images/stop.svg'
import ScanUploadTick from '../../images/tickMark.svg'
import Upload from '../../images/upload.svg'
import {
  getAllScanAsync,
  getScanByIdAsync,
  initiateScanUploadAsync,
  resetInitiateScanUpload,
  resetIsScanDue,
  resetIsScanEmpty,
  resetScanByIdStatus,
  resetScans,
  resetScansStatus,
  resetScanUpload,
  setIsScanDue,
} from '../../redux/current'
import {
  callInNextPatientAsync,
  patientQuestionarieAsync,
  patientVisitAsync,
  postTreatmentType,
  resetCalledInTwiceError,
  startChiropracticAsync,
  stopChiropracticAsync,
} from '../../redux/lobby'
import { getNotesAsync, getNotesByStatusAsync } from '../../redux/notes'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { APIStatus } from '../../redux/types'
import NotesDrawer from '../NotesDrawer/notesDrawer'

interface Visit {
  id: string
  appointmentTime: string
  // Add other properties here
}
export enum NoteStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export default function CurrentTab({
  startRecording,
  stopRecording,
  // recordedAudio,
  // resetRecording,
  setUploadUrl,
}: any) {
  const callInPatientStatus = useAppSelector((state) => state.lobby.callInPatientStatus)
  const currentPatient = useAppSelector((state) => state.lobby.currentPatient)
  const startChiropracticStatus = useAppSelector((state) => state.lobby.startChiropracticStatus)
  const stopChiropracticStatus = useAppSelector((state) => state.lobby.stopChiropracticStatus)
  const patientQuestionaries = useAppSelector((state) => state.lobby.patientQuestionaries)
  const patientNotes = useAppSelector((state) => state.notes.patientNotes)
  const queue = useAppSelector((state) => state.lobby.queue)
  const queueStatus = useAppSelector((state) => state.lobby.queueStatus)
  const visit = useAppSelector((state) => state.lobby.visit)
  const visitStatus = useAppSelector((state) => state.lobby.visitStatus)
  const initiateScanUpload = useAppSelector((state) => state.current.initiateScanUpload)
  const initiateScanStatus = useAppSelector((state) => state.current.initiateScanStatus)
  const pollScanUpload = useAppSelector((state) => state.current.scanUpload)
  const pollScanUploadStatus = useAppSelector((state) => state.current.scanUploadStatus)
  const isPollingInProgress = useAppSelector((state) => state.current.isPollingInProgress)
  const isScanDue = useAppSelector((state) => state.current.isScanDue)
  const [treatmentType, setTreatmentType] = useState<string | null>(null)
  const [openScanReport, setOpenScanReport] = useState(false)
  // const [isScanUploadInitiated, setIsScanUploadInitiated] = useState(false)
  // const [isScanUploadCompleted, setIsScanUploadCompleted] = useState(false)

  const {
    startPolling,
    stopPolling,
    scanUploadFailed,
    scanUploadSuccess,
    _changeScanUploadSuccess,
    _changeScanUploadFailed,
  } = useScanPolling()

  // const [status, setStatus] = useState('')

  const dispatch = useAppDispatch()

  useLobbyPolling()
  //const { startRecording, stopRecording, recordedAudio, resetRecording } = useRecordingHook()

  // useEffect(() => {
  //   setImmediate(() => {
  //     window.scrollTo(0, 0)
  //   })
  // })

  const currentPatientNote = useMemo(() => {
    if (currentPatient?.patientId) {
      return patientNotes[currentPatient?.patientId]
    } else {
      return null
    }
  }, [patientNotes, currentPatient])

  const currentNote = useMemo(() => {
    if (currentPatientNote && currentPatientNote.notes && currentPatientNote.notes.length > 0) {
      let temp = [...currentPatientNote?.notes]

      temp = temp.filter((note) => note.status === NoteStatus.ACCEPTED)

      temp.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })

      return temp[temp.length - 1]
    } else {
      return null
    }
  }, [currentPatientNote])

  const treatmentTypeChange = (type: string | null) => {
    setTreatmentType((prevTreatmentType: any) => (prevTreatmentType === type ? null : type))
  }

  const handleStartStop = () => {
    if (currentPatient?.queueStatus === 'waiting') {
      startRecording()
      dispatch(startChiropracticAsync(currentPatient?.visitId))
    } else if (currentPatient?.queueStatus === 'service_in_progress') {
      setUploadUrl(currentPatient.notesUploadUrl)
      stopRecording()
      dispatch(
        stopChiropracticAsync({ visitId: currentPatient?.visitId, queue: queue, currentPatient: currentPatient }),
      )
    } else {
      //reset all the scan upload related values from redux and the scan polling hook before calling next patient.
      dispatch(resetIsScanDue())
      dispatch(resetScanUpload())
      dispatch(resetInitiateScanUpload())
      _changeScanUploadFailed(false)
      _changeScanUploadSuccess(false)

      dispatch(callInNextPatientAsync({ queue }))
    }
  }

  useEffect(() => {
    if (currentPatient) {
      dispatch(patientQuestionarieAsync({ patientId: currentPatient?.patientId }))
    }
  }, [currentPatient])

  useEffect(() => {
    if (currentPatient) {
      if (currentPatient.notesUploadUrl) {
        setUploadUrl(currentPatient.notesUploadUrl)
      }
    }
  }, [currentPatient])

  useEffect(() => {
    if (currentPatient) {
      dispatch(patientVisitAsync({ patientId: currentPatient?.patientId }))
    }
  }, [currentPatient])

  useEffect(() => {
    if (currentPatient) {
      dispatch(getNotesAsync(currentPatient?.patientId))
    }
  }, [currentPatient])

  useEffect(() => {
    if (stopChiropracticStatus === APIStatus.fulfilled) {
      dispatch(getNotesByStatusAsync())
    }
  }, [stopChiropracticStatus])

  useEffect(() => {
    if (visit) {
      setTreatmentType(visit?.treatmentType === null ? null : visit?.treatmentType)
    }
  }, [visit])

  useEffect(() => {
    if (initiateScanUpload && initiateScanUpload?.code === 'success') {
      console.log('start Polling useEffect is run')
      dispatch(setIsScanDue())
      startPolling()
    }
  }, [initiateScanStatus])

  useEffect(() => {
    if (pollScanUpload && pollScanUpload?.data?.status === 'completed') {
      stopPolling()
      _changeScanUploadSuccess(true)
    } else if (pollScanUpload && pollScanUpload?.data?.status === 'failed') {
      stopPolling()
      _changeScanUploadFailed(true)
    }
  }, [pollScanUpload])

  if (queueStatus === APIStatus.pending) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin style={{ marginBottom: '130px' }}></Spin>
      </div>
    )
  } else if (!currentPatient && queue?.length > 0) {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            children={'Next Patient'}
            type="primary"
            style={{ width: '198px', marginBottom: '130px' }}
            loading={callInPatientStatus === APIStatus.pending}
            onClick={() => {
              dispatch(callInNextPatientAsync({ queue, visitId: queue[0]?.visitId }))
            }}
          ></Button>
        </div>
      </div>
    )
  } else if (currentPatient) {
    return (
      // <Wrapper>
      <Flex gap={60}>
        <Section>
          <Flex vertical style={{ width: '100%', justifyContent: 'space-between', marginBottom: '15px' }}>
            <Flex vertical gap={12}>
              <Flex gap={20}>
                {visit?.isNew && (
                  <Button
                    children={'New'}
                    style={{
                      height: '23px',
                      position: 'absolute',
                      background: '#007f7c',
                      color: '#e5e7eb',
                      width: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      left: 49,
                      top: 195,
                      marginLeft: 8,
                      marginTop: -8,
                      zIndex: 1,
                      fontSize: 12,
                      cursor: 'default',
                    }}
                  ></Button>
                )}
                {visit?.membership && (
                  <img
                    src={visit?.membershipStatus === 'in-active' ? KiroLogoRed : KiroLogo}
                    style={{
                      height: '30px',
                      position: 'absolute',
                      width: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      left: 49,
                      top: 21,
                      zIndex: 1,
                      fontSize: 12,
                      cursor: 'default',
                      marginLeft: 8,
                      marginTop: 8,
                    }}
                  ></img>
                )}
                <AgeBadge>{calculateAge(currentPatient?.dob)}</AgeBadge>
                <Avatar
                  shape="square"
                  // size={220}
                  style={{ borderRadius: 8, minWidth: '210px', minHeight: '200px' }}
                  icon={currentPatient?.profilePic ? <img src={currentPatient?.profilePic}></img> : <UserOutlined />}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    height: 'auto',
                  }}
                >
                  {/* <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}> */}
                  {/* <Button
                      children={'7'}
                      style={{
                        height: '22px',
                        width: '41px',
                        fontSize: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '6px',
                        border: '1px solid #2A2D56',
                      }}
                    ></Button> */}
                  {/* </div> */}
                  <NameWrapper>
                    <Span style={{ textTransform: 'uppercase' }}>{currentPatient?.firstname}</Span>
                    <Span style={{ textTransform: 'uppercase' }}>{currentPatient?.lastname}</Span>
                  </NameWrapper>

                  {/* <Flex gap={10} style={{ marginTop: '8px', marginBottom: '0px' }}>
                    <PatientInfo>
                      <PatientInfoItem>{calculateAge(currentPatient?.dob)}</PatientInfoItem>
                      <PatientInfoItem>{!currentPatient?.gender ? '' : currentPatient?.gender}</PatientInfoItem>
                    </PatientInfo>
                  </Flex> */}
                  {/* <IssueSvgCard patientQuestionaries={patientQuestionaries}></IssueSvgCard> */}
                  <MetricWrapper>
                    {visit?.feedbackValue === null ? (
                      <FlashingMetricCard>
                        <MetricTitle>REV</MetricTitle>
                        <MetricValue>{visit?.feedbackValue ?? '-'}</MetricValue>
                      </FlashingMetricCard>
                    ) : (
                      <MetricCard>
                        <MetricTitle>REV</MetricTitle>
                        <MetricValue>{visit?.feedbackValue ?? '-'}</MetricValue>
                      </MetricCard>
                    )}
                    {visit && visit?.metric && visit?.metric?.dst > 7 ? (
                      <FlashingMetricCard>
                        <MetricTitle>DSL</MetricTitle>
                        <MetricValue>{visit?.metric?.dst ?? '-'}</MetricValue>
                      </FlashingMetricCard>
                    ) : (
                      <MetricCard>
                        <MetricTitle>DSL</MetricTitle>
                        <MetricValue>{visit?.metric?.dst ?? '-'}</MetricValue>
                      </MetricCard>
                    )}
                    {visit && visit?.metric && visit?.metric?.apm && parseFloat(visit?.metric?.apm) < 4 ? (
                      <FlashingMetricCard>
                        <MetricTitle>APM</MetricTitle>
                        <MetricValue>{visit?.metric?.apm ?? '-'}</MetricValue>
                      </FlashingMetricCard>
                    ) : (
                      <MetricCard>
                        <MetricTitle>APM</MetricTitle>
                        <MetricValue>{visit?.metric?.apm ?? '-'}</MetricValue>
                      </MetricCard>
                    )}
                    {visit?.metric?.membershipWeek === 4 && (
                      <FlashingMetricCard>
                        <MetricTitle>ZON</MetricTitle>
                        <MetricValue>
                          {visit?.metric?.membershipMonth ?? '-'}.{visit?.metric?.membershipWeek ?? '-'}
                        </MetricValue>
                      </FlashingMetricCard>
                    )}
                    {visit?.metric?.membershipWeek !== 4 && (
                      <MetricCard
                        style={{
                          backgroundColor: visit?.metric?.membershipWeek === 3 ? '#ff0000' : '#10123d',
                          color: visit?.metric?.membershipWeek === 3 ? '#e5e7eb' : '#007f7c',
                        }}
                      >
                        <MetricTitle>ZON</MetricTitle>
                        {visit?.metric?.membershipMonth === 0 && visit?.metric?.membershipWeek === 0 ? (
                          <MetricValue>{'-'}</MetricValue>
                        ) : (
                          <MetricValue>
                            {visit?.metric?.membershipMonth ?? '-'}.{visit?.metric?.membershipWeek ?? '-'}
                          </MetricValue>
                        )}
                      </MetricCard>
                    )}
                    <MetricCard>
                      <MetricTitle>PCR</MetricTitle>
                      <MetricValue>{(visit?.subscriptionCount ?? 0) + (visit?.onDemandCount ?? 0)}</MetricValue>
                    </MetricCard>
                    <MetricCard
                      onClick={() => {
                        if (visitStatus !== APIStatus.pending) {
                          setOpenScanReport(true)
                        }
                      }}
                    >
                      <MetricTitle>NSS</MetricTitle>
                      <MetricValue>{visit?.metric?.scanScore ?? '-'}</MetricValue>
                    </MetricCard>
                  </MetricWrapper>
                  <TreatmentTypeContainer>
                    <TreatmentType
                      children={'Corrective'}
                      onClick={() => {
                        treatmentTypeChange('corrective')
                        //checking if previous treatmentType value is corrective
                        if (treatmentType === 'corrective') {
                          dispatch(
                            postTreatmentType({
                              patientId: currentPatient?.patientId || '',
                              data: { treatmentType: null },
                            }),
                          )
                        } else {
                          dispatch(
                            postTreatmentType({
                              patientId: currentPatient?.patientId || '',
                              data: { treatmentType: 'corrective' },
                            }),
                          )
                        }
                      }}
                      style={{
                        background: treatmentType && treatmentType === 'corrective' ? '#007F7C' : '#10123d',
                        color: treatmentType && treatmentType === 'corrective' ? '#fffeff' : '#e5e7eb',
                        border: treatmentType !== 'corrective' ? '1px solid #343755' : '1px solid #10123d',
                      }}
                    ></TreatmentType>
                    <TreatmentType
                      children={'Symptomatic'}
                      onClick={() => {
                        treatmentTypeChange('symptomatic')
                        if (treatmentType === 'symptomatic') {
                          dispatch(
                            postTreatmentType({
                              patientId: currentPatient?.patientId || '',
                              data: { treatmentType: null },
                            }),
                          )
                        } else {
                          dispatch(
                            postTreatmentType({
                              patientId: currentPatient?.patientId || '',
                              data: { treatmentType: 'symptomatic' },
                            }),
                          )
                        }
                      }}
                      style={{
                        background: treatmentType && treatmentType === 'symptomatic' ? '#007F7C' : '#10123d',
                        color: treatmentType && treatmentType === 'symptomatic' ? '#fffeff' : '#e5e7eb',
                        border: treatmentType !== 'symptomatic' ? '1px solid #343755' : '1px solid #10123d',
                      }}
                    ></TreatmentType>
                  </TreatmentTypeContainer>
                </div>
              </Flex>
              <IssuesCard patientQuestionaries={patientQuestionaries}></IssuesCard>
            </Flex>
            <ButtonContainer>
              {(visit?.membership && visit?.scanDue) || (visit?.membership && isScanDue) ? (
                <Button
                  type="primary"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'transparent',
                    border:
                      pollScanUpload && pollScanUpload?.data && pollScanUpload?.data?.status === 'completed'
                        ? '1px solid #007F7C'
                        : pollScanUpload?.data?.status === 'failed'
                          ? '1px solid #E33939'
                          : '1px solid #E5E7EB',

                    marginTop: '-20px',
                  }}
                  onClick={() => {
                    if (isPollingInProgress || scanUploadSuccess || scanUploadFailed) return
                    dispatch(initiateScanUploadAsync({ visitId: currentPatient?.visitId }))
                  }}
                  loading={isPollingInProgress && !scanUploadSuccess && !scanUploadFailed}
                >
                  {!isPollingInProgress && !scanUploadSuccess && !scanUploadFailed && (
                    <ButtonChildren style={{ color: '#E5E7EB' }}>
                      <img src={Upload}></img>
                      <div>Upload Scan</div>
                    </ButtonChildren>
                  )}
                  {pollScanUpload &&
                    pollScanUpload?.data &&
                    pollScanUpload?.data?.status === 'completed' &&
                    !scanUploadFailed &&
                    scanUploadSuccess && (
                      <ButtonChildren>
                        <img src={ScanUploadTick}></img>
                        <div>Scan Uploaded</div>
                      </ButtonChildren>
                    )}
                  {pollScanUpload &&
                    pollScanUpload?.data &&
                    pollScanUpload?.data?.status === 'failed' &&
                    !scanUploadSuccess &&
                    scanUploadFailed && (
                      <ButtonChildren style={{ color: '#E33939' }}>
                        <img src={errorSvg}></img>
                        <div>Upload Failed</div>
                      </ButtonChildren>
                    )}
                </Button>
              ) : null}
              <Button
                type="primary"
                loading={
                  startChiropracticStatus === APIStatus.pending ||
                  stopChiropracticStatus === APIStatus.pending ||
                  callInPatientStatus === APIStatus.pending
                }
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: currentPatient.queueStatus === 'service_in_progress' ? '#E33939' : '',
                  // opacity: isPollingInProgress ? 0.5 : 1,
                }}
                onClick={handleStartStop}
                // onClick={isPollingInProgress ? undefined : handleStartStop}
              >
                {currentPatient.queueStatus === 'waiting' ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {startChiropracticStatus !== APIStatus.pending && (
                        <img src={Start} style={{ marginRight: 8 }}></img>
                      )}
                      Start
                    </div>
                  </>
                ) : currentPatient.queueStatus === 'service_in_progress' ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {stopChiropracticStatus !== APIStatus.pending && (
                        <img src={Stop} style={{ marginRight: 8 }}></img>
                      )}
                      Stop
                    </div>
                  </>
                ) : queue?.length > 0 ? (
                  'Next Patient'
                ) : (
                  'Done'
                )}
              </Button>
            </ButtonContainer>
          </Flex>
        </Section>
        <Section>
          <Flex
            gap={17}
            style={{
              flexDirection: 'row-reverse',
              alignContent: 'flex-end',
              flex: '1',
              width: '100%',
              // marginTop: 10,
            }}
          >
            {currentNote && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    touchAction: 'none',
                    WebkitUserSelect: 'none',
                  }}
                >
                  <CalenderWrapper>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#2A2D56',
                        borderTopLeftRadius: 6,
                        borderTopRightRadius: 6,
                        padding: '3px 9px',
                        // marginTop: 10,
                      }}
                    >
                      {currentNote?.createdAt ? format(new Date(currentNote.createdAt), 'MMM') : ''}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        background: '#10123d',
                        padding: '6px 15px',
                        borderBottomLeftRadius: 6,
                        borderBottomRightRadius: 6,
                      }}
                    >
                      {currentNote?.createdAt ? format(new Date(currentNote.createdAt), 'dd') : ''}
                    </div>
                  </CalenderWrapper>
                  {/* <DayWrapper>
                    {currentNote?.createdAt ? format(new Date(currentNote.createdAt), 'MMM') : ''}
                  </DayWrapper>
                  <DateWraapper>
                    <span style={{ marginTop: 10 }}>
                      {currentNote?.createdAt ? format(new Date(currentNote.createdAt), 'dd') : ''}
                    </span>
                  </DateWraapper> */}
                  <NotesDrawer notes={currentPatientNote?.notes}></NotesDrawer>
                </div>
              </>
            )}
            {currentNote && (
              <Flex vertical gap={8} style={{ width: '100%', marginBottom: '15px' }}>
                <CardWrapper style={{ marginTop: 12 }}>
                  <Text style={{ zIndex: '20' }}>Subjective</Text>
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      resize: 'none',
                      padding: '0px 10px 0px 10px',
                      marginTop: 30,
                    }}
                  >
                    {currentNote?.subjective}
                  </div>
                </CardWrapper>

                <CardWrapper>
                  <Text style={{ zIndex: '20' }}>Objective</Text>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      resize: 'none',
                      padding: '0px 10px 0px 10px',
                      marginTop: 30,
                    }}
                  >
                    {currentNote?.objective}
                  </div>
                </CardWrapper>
                <CardWrapper>
                  <Text style={{ zIndex: '20' }}>Assessment</Text>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      resize: 'none',
                      padding: '0px 10px 0px 10px',
                      marginTop: 30,
                    }}
                  >
                    {currentNote?.assessment}
                  </div>
                </CardWrapper>
                <CardWrapper>
                  <Text style={{ zIndex: '20' }}>Plan</Text>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      resize: 'none',
                      padding: '0px 10px 0px 10px',
                      marginTop: 30,
                    }}
                  >
                    {currentNote?.plan}
                  </div>
                </CardWrapper>
              </Flex>
            )}

            {openScanReport && (
              <PopUp
                style={{ padding: '0px', borderRadius: '4px' }}
                open={openScanReport}
                centered
                mask={false}
                maskClosable={true}
                footer={null}
                closable={false}
                onCancel={() => {
                  setOpenScanReport(false)
                }}
              >
                <ScanReport patientId={visit?.user.id}></ScanReport>
              </PopUp>
            )}

            {/* {!currentNote && (
              <CardWrapper style={{ height: '185px' }}>
                <TextTitle>Screener feedback</TextTitle>
                <FeedbackContent>
                  {currentPatient.screenerFeedback ? currentPatient.screenerFeedback : 'Feedback is not provided'}
                </FeedbackContent>
              </CardWrapper>
            )} */}
          </Flex>
        </Section>
      </Flex>
      // </Wrapper>
    )
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '130px',
        }}
      >
        <EmptyText>Coast is clear, time for a break!</EmptyText>
      </div>
    </div>
  )
}

// Scan Report

export function ScanReport({ patientId }: { patientId: any }) {
  const dispatch = useAppDispatch()
  const scans = useAppSelector((state) => state.current.scans)
  const isScanEmpty = useAppSelector((state) => state.current.isScanEmpty)
  const scansStatus = useAppSelector((state) => state.current.scansStatus)
  const scansByIdStatus = useAppSelector((state) => state.current.scanByIdStatus)
  const scansById = useAppSelector((state) => state.current.scanById)
  const visit = useAppSelector((state) => state.lobby.visit)
  const [scanReport, setScanReport]: any = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPageIndex, setLastPageIndex] = useState(-1)
  const [processFinished, setProcessFinished] = useState(false)
  const defaultScanReport = [
    {
      spineLevel: 'C2',
      value: 55,
    },
    {
      spineLevel: 'C4',
      value: 55,
    },
    {
      spineLevel: 'C6',
      value: 55,
    },
    {
      spineLevel: 'T1',
      value: 55,
    },
    {
      spineLevel: 'T3',
      value: 75,
    },
    {
      spineLevel: 'T5',
      value: 75,
    },
    {
      spineLevel: 'T7',
      value: 75,
    },
    {
      spineLevel: 'T9',
      value: 75,
    },
    {
      spineLevel: 'T11',
      value: 105,
    },
    {
      spineLevel: 'L1',
      value: 105,
    },
    {
      spineLevel: 'L3',
      value: 105,
    },
    {
      spineLevel: 'L5',
      value: 105,
    },
  ]
  const scanDate = useMemo(() => {
    const date = scansById?.appointmentTime ? parseISO(scansById?.appointmentTime) : null
    const day = date ? format(date, 'EEEE') : null
    const datePart = date ? format(date, 'MMMM d') : null

    const formattedDateObject = {
      day: day,
      date: datePart,
    }
    return formattedDateObject
  }, [scansById])

  const range = useMemo(() => {
    const r: any = {
      c2: {
        min: 2.0,
        good: 3.0,
        high: 7.0,
        max: 50,
      },
      c4: {
        min: 2.0,
        good: 3.0,
        high: 7.0,
        max: 50,
      },
      c6: {
        min: 2.0,
        good: 3.0,
        high: 8.0,
        max: 50,
      },
      t1: {
        min: 2.0,
        good: 3.0,
        high: 8.3,
        max: 50,
      },
      t3: {
        min: 3.5,
        good: 5.5,
        high: 7.0,
        max: 50,
      },
      t5: {
        min: 3.5,
        good: 5.5,
        high: 7.0,
        max: 50,
      },
      t7: {
        min: 3.5,
        good: 5.5,
        high: 7.0,
        max: 50,
      },
      t9: {
        min: 3.0,
        good: 5.0,
        high: 7.0,
        max: 50,
      },
      t11: {
        min: 3.0,
        good: 5.0,
        high: 7.0,
        max: 50,
      },
      l1: {
        min: 4.3,
        good: 6.3,
        high: 9.4,
        max: 50,
      },
      l3: {
        min: 4.1,
        good: 7.1,
        high: 9.5,
        max: 50,
      },
      l5: {
        min: 4.3,
        good: 7.3,
        high: 9.4,
        max: 50,
      },
    }
    return r
  }, [])

  useEffect(() => {
    dispatch(getAllScanAsync({ patientId: patientId }))

    return () => {
      dispatch(resetScansStatus())
      dispatch(resetScanByIdStatus())
      dispatch(resetScans())
      dispatch(resetIsScanEmpty())
    }
  }, [])

  useEffect(() => {
    if (scans && isScanEmpty === false) {
      const scanArr = scans?.userScan?.length
      const lastIndex = scanArr ? scanArr - 1 : -1
      setLastPageIndex(lastIndex)
    }
  }, [scansStatus])

  useEffect(() => {
    if (scansStatus === APIStatus.fulfilled) {
      setIsLoading(false)
    }
  }, [scansStatus])

  useEffect(() => {
    if (scansStatus === APIStatus.fulfilled && currentPage >= 0 && isScanEmpty === false) {
      dispatch(getScanByIdAsync({ patientId: patientId, scanId: scans?.userScan[currentPage]?.id || '' }))
    }
  }, [currentPage, scansStatus])

  useEffect(() => {
    if (scansByIdStatus === APIStatus.fulfilled && scansById && scansById?.userScanDatas) {
      const desiredOrder = ['c2', 'c4', 'c6', 't1', 't3', 't5', 't7', 't9', 't11', 'l1', 'l3', 'l5']

      const scanSpineLowerCased = scansById?.userScanDatas?.map((d) => {
        return {
          ...d,
          spineLevel: d.spineLevel ? d.spineLevel.toLowerCase() : d.spineLevel,
        }
      })

      const orderedScanReport = scanSpineLowerCased.sort((a, b) => {
        return desiredOrder.indexOf(a.spineLevel) - desiredOrder.indexOf(b.spineLevel)
      })

      let highestLeftValue = 0
      let highestRightValue = 0
      let maxValue = 0

      orderedScanReport?.forEach((obj) => {
        if (obj.leftValue > highestLeftValue) {
          highestLeftValue = obj.leftValue
        }
      })

      orderedScanReport?.forEach((obj) => {
        if (obj.rightValue > highestRightValue) {
          highestRightValue = obj.rightValue
        }
      })

      if (highestLeftValue > highestRightValue) {
        maxValue = highestLeftValue
      } else {
        maxValue = highestRightValue
      }

      // const maxLogValue = Math.log(maxValue)

      const getBarWidth = (n: number) => {
        const FIRST_SCALE = 0.6
        let scaleFactor = 0.0
        let percent = 0.0
        if (n <= 20) {
          percent = n / 20.0
          scaleFactor = percent * FIRST_SCALE
        } else if (n > 20 && n <= 150) {
          percent = n / 200.0
          scaleFactor = 0.5 + percent * (1 - FIRST_SCALE)
        } else if (n > 150) {
          scaleFactor = 0.8
        }
        return scaleFactor
      }

      const processedScanReport = orderedScanReport?.map((item: any) => {
        const { spineLevel, leftValue, rightValue, userScanId } = item

        const getBarColor = (value: any, spineLevel: any) => {
          if (value > 0 && value <= spineLevel.min) {
            return '#E5E7EB'
          } else if (value > spineLevel.min && value < spineLevel.good) {
            return '#007F7C'
          } else if (value >= spineLevel.good && value < spineLevel.high) {
            return '#E5E7EB'
          } else if (value >= spineLevel.high) {
            return '#565E7F'
          } else {
            return '#565E7F'
          }
        }
        let leftBarColor = null
        let rightBarColor = null
        if (range[spineLevel]) {
          if (leftValue) {
            leftBarColor = getBarColor(leftValue, range[spineLevel])
          }

          if (rightValue) {
            rightBarColor = getBarColor(rightValue, range[spineLevel])
          }
        }

        let leftTextPercent = rightValue === 0 ? 100 : Math.round((leftValue / rightValue - 1) * 100)
        let rightTextPercent = leftValue === 0 ? 100 : Math.round((rightValue / leftValue - 1) * 100)
        let isBothEqual = false
        let hideText = false
        let showLeftText = false
        let showRightText = false

        if (leftValue > rightValue) {
          showLeftText = true
        }
        if (rightValue > leftValue) {
          showRightText = true
        }
        if (leftValue === rightValue) {
          isBothEqual = true
        }

        // let maxRangeValue = range[spineLevel]?.max ?? 1

        // const leftBarWidth = (Math.log(leftValue) / maxLogValue) * 100 * 1.5
        // const rightBarWidth = (Math.log(rightValue) / maxLogValue) * 100 * 1.5

        // const leftBarWidth = Math.round((leftValue / 40) * 100 * 3.5)
        // const rightBarWidth = Math.round((rightValue / 40) * 100 * 3.5)

        const leftBarWidth = getBarWidth(leftValue) * 100 * 4
        const rightBarWidth = getBarWidth(rightValue) * 100 * 4

        console.log(leftBarWidth, rightBarWidth, 'left and right bar width')

        const leftBarPxLength = leftTextPercent.toString().length
        const rightBarPxLength = rightTextPercent.toString().length

        const barToShowLength = leftTextPercent > rightTextPercent ? leftBarPxLength : rightBarPxLength
        const barWidth = leftValue > rightValue ? leftBarWidth : rightBarWidth

        if (barToShowLength === 1 && barWidth < 59) {
          hideText = true
        } else if (barToShowLength === 2 && barWidth < 66.5) {
          hideText = true
        } else if (barToShowLength === 3 && barWidth < 74) {
          hideText = true
        } else if (barToShowLength >= 4 && barWidth < 81.5) {
          hideText = true
        } else if (barToShowLength >= 5) {
          hideText = true
        }

        return {
          spineLevel,
          leftTextPercent,
          rightTextPercent,
          showRightText,
          showLeftText,
          isBothEqual,
          leftBarWidth,
          rightBarWidth,
          rightBarColor,
          leftBarColor,
          leftValue,
          rightValue,
          hideText,
          userScanId,
        }
      })
      setScanReport(processedScanReport)
      setProcessFinished(true)
    }
  }, [scansById])

  if (isLoading === true) {
    return (
      <ScanReportWrapper>
        <LoadingContainer>
          <Spin></Spin>
        </LoadingContainer>
        <Footer></Footer>
      </ScanReportWrapper>
    )
  } else {
    return isScanEmpty === true ? (
      <ScanReportWrapper>
        {scansStatus === APIStatus.fulfilled && (
          <ScanReportContainer>
            {defaultScanReport.map((scan: any) => {
              return (
                <Container>
                  <LeftBarContainer>
                    <LeftBar
                      style={{
                        width: `${scan.value}px`,
                        borderBottom: `32px solid #007F7C`,
                      }}
                    ></LeftBar>
                  </LeftBarContainer>
                  <HexagonContainer>
                    <Hexagon>
                      <HexagonLabel>{scan.spineLevel}</HexagonLabel>
                    </Hexagon>
                  </HexagonContainer>
                  <RightBarContainer>
                    <RightBar
                      style={{
                        width: `${scan.value}px`,
                        borderBottom: `32px solid #007F7C`,
                      }}
                    ></RightBar>
                  </RightBarContainer>
                </Container>
              )
            })}
          </ScanReportContainer>
        )}
        <Footer style={{ height: '72px' }}></Footer>
      </ScanReportWrapper>
    ) : (
      <ScanReportWrapper>
        {scansByIdStatus === APIStatus.pending && (
          <LoadingContainer>
            <Spin></Spin>
          </LoadingContainer>
        )}
        {scansByIdStatus === APIStatus.fulfilled && scanReport && processFinished && (
          <ScanReportContainer>
            {scanReport?.map((scan: any) => {
              return (
                <Container>
                  <LeftBarContainer>
                    {scan.userScanId === scans?.firstScan?.id && scans?.userScan && scans?.userScan?.length > 1 && (
                      <LeftCircle></LeftCircle>
                    )}
                    <LeftBar
                      style={{
                        width: `${scan.leftBarWidth}px`,
                        minWidth: '40px',
                        maxWidth: '195px',
                        borderBottom: `32px solid ${scan.leftBarColor}`,
                        color: scan.leftBarColor === '#E5E7EB' ? '#050624' : '#E5E7EB',
                      }}
                    >
                      <LeftBarText>
                        {scan.showLeftText === true && scan.hideText !== true && scan.isBothEqual === false
                          ? `${scan.leftTextPercent}%`
                          : ''}
                      </LeftBarText>
                    </LeftBar>
                  </LeftBarContainer>
                  <HexagonContainer>
                    <Hexagon>
                      <HexagonLabel>{scan.spineLevel}</HexagonLabel>
                    </Hexagon>
                  </HexagonContainer>
                  <RightBarContainer>
                    <RightBar
                      style={{
                        width: `${scan.rightBarWidth}px`,
                        minWidth: '40px',
                        maxWidth: '195px',
                        borderBottom: `32px solid ${scan.rightBarColor}`,
                        color: scan.rightBarColor === '#E5E7EB' ? '#050624' : '#E5E7EB',
                      }}
                    >
                      <RightBarText>
                        {scan.showRightText === true && scan.hideText !== true && scan.isBothEqual === false
                          ? `${scan.rightTextPercent}%`
                          : ''}
                      </RightBarText>
                    </RightBar>
                    {scan.userScanId === scans?.firstScan?.id && scans?.userScan && scans?.userScan?.length > 1 && (
                      <RightCircle></RightCircle>
                    )}
                  </RightBarContainer>
                </Container>
              )
            })}
            {/* {currentPage === 0 && visit?.membership === true && (
              <ScanDueIn>Your next scan will be performed at the beginning of the next cycle.</ScanDueIn>
            )} */}
          </ScanReportContainer>
        )}
        <Footer>
          <ProfileContainer>
            <Avatar
              size={50}
              shape="circle"
              style={{ minWidth: '50px', minHeight: '50px' }}
              icon={
                scansByIdStatus === APIStatus.pending ? (
                  <UserOutlined />
                ) : (
                  <img src={scansById?.chiroProfilePic || ''}></img>
                )
              }
            />
            <ScanDayAndDate>
              <Day>{scanDate?.day}</Day>
              <MonthAndDate>{scanDate?.date}</MonthAndDate>
            </ScanDayAndDate>
          </ProfileContainer>

          <ScoreContainer>
            <Score>{scansById?.scanScore ?? '-'}</Score>
            <PageToggle>
              <Arrows
                style={{ background: currentPage === lastPageIndex ? '#565E7F' : '#007f7c' }}
                onClick={() => {
                  if (currentPage < lastPageIndex) {
                    setCurrentPage(currentPage + 1)
                    setProcessFinished(false)
                  } else {
                    setCurrentPage(lastPageIndex)
                  }
                }}
              >
                <img src={leftArrow} alt="arrow" />
              </Arrows>
              <Arrows
                style={{ background: currentPage === 0 ? '#565E7F' : '#007f7c' }}
                onClick={() => {
                  if (currentPage !== 0) {
                    setCurrentPage(currentPage - 1)
                    setProcessFinished(false)
                  } else {
                    setCurrentPage(0)
                  }
                }}
              >
                <img src={rightArrow} alt="arrow" />
              </Arrows>
            </PageToggle>
          </ScoreContainer>
        </Footer>
      </ScanReportWrapper>
    )
  }
}
